// GNB
var gnb = document.querySelector('.gnb');
var lang = gnb.querySelector('.lang');
var hamburger = gnb.querySelector('.hamburger');
var menu = gnb.querySelector('.menu');

// 언어 선택 레이어 열기
if (lang) {
    lang.addEventListener('click', function(event) {
        event.preventDefault();
        if (!lang.classList.contains('open')) {
            lang.classList.add('open');
        } else {
            lang.classList.remove('open');
        }
    });
}

// 메뉴 레이어 열기
if (hamburger) {
    hamburger.addEventListener('click', function(event) {
        event.preventDefault();
        if (!menu.classList.contains('open')) {
            menu.classList.add('open');
        } else {
            menu.classList.remove('open');
        }
    });
}

// 공개모드에 따른 비밀번호 입력 필드 활성화 또는 비활성화
var passwordLable = document.getElementById('passwordLable');
var passwordInput = document.getElementById('passwordInput');
var togglePassword = function(isPrivate) {
    if (!passwordLable || !passwordInput) {
        return;
    }
    if (!isPrivate) {
        passwordLable.style.display = 'none';
        passwordInput.style.display = 'none';
    } else {
        passwordLable.style.display = 'block';
        passwordInput.style.display = 'block';
    }
};

var isPrivate = function() {
    var radios = document.getElementsByName('is_private');
    for (var i = 0; i < radios.length; i++) {
        if (radios[i].checked) {
            return radios[i].value === '1';
            break;
        }
    }
};

togglePassword(isPrivate());

// faq
var accordion = document.getElementById('accordion');
if (accordionTitle) {
    var accordionTitle = accordion.querySelectorAll('dt');
    var accordionActive = 'active';
    for (var i = 0; i < accordionTitle.length; i++) {
        accordionTitle[i].addEventListener('click', function(event) {
            event.preventDefault();
            if (this.classList.contains(accordionActive)) {
                this.classList.remove(accordionActive);
            } else {
                this.classList.add(accordionActive);
            }
        }, false);
    }
}
